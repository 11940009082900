import { PageProps } from "gatsby"
import React from "react"
import { ThemeProvider } from "styled-components"
import Divider from "../components/divider"
import BasicHeader from "../components/header/basic"
import PageHeading from "../components/page-heading"
import Seo from "../components/seo"
import { FlexColumn } from "../components/system/flex-column"
import { FlexRow } from "../components/system/flex-row"
import { Typography } from "../components/system/typography"
import GlobalStyles from "../styles/globals"
import * as Styled from "../styles/pages/about"
import { blueTheme } from "../styles/themes"
import ArrowRight from "../images/arrow-right.svg"
import mdx from "../components/mdx"
import ContinueLink, { StartReadingLink } from "../components/continue-link"
import { useProgress } from "../hooks/useProgress"

const AboutPage: React.FC<PageProps> = () => {
  const [progress] = useProgress()

  return (
    <ThemeProvider theme={blueTheme}>
      <GlobalStyles variant="blue" />
      <Seo title="O książce" />
      <BasicHeader divider />
      <Styled.Layout as="main">
        <PageHeading>O książce</PageHeading>
        <Styled.Spacer />
        <Styled.Content>
          <Styled.Abstract as="section">
            <Typography variant="body" typo="azo">
              Wykresy, schematy i diagramy nie są wyłącznie ilustracjami. Jak
              dowodzi <mdx.q>Model i metafora</mdx.q>, można je dziś uznać za
              niezbędne narzędzia ułatwiające tworzenie współczesnej
              humanistyki. Wykorzystując jednocześnie teksty i obrazy, książka w
              zwięzły sposób przedstawia szereg zagadnień istotnych dla każdego
              historyka, socjologa czy badacza literatury.
              <br role="separator" />
              <br />
              Na <mdx.q>Model i metaforę</mdx.q> składają się teoretyczne
              podstawy myślenia obrazem, zarys historycznych relacji słowa i
              obrazu w humanistyce, a także praktyczne wskazówki dla tych,
              którzy chcieliby korzystać z obrazów w trakcie planowania i
              pisania tekstów. Inni czytelnicy mogą traktować tę książkę jak
              przewodnik, ułatwiający poruszanie się w epoce obrazów. Jak
              przystało na książkę o komunikacji wizualnej,{" "}
              <mdx.q>Model i metafora</mdx.q>
              jest połączeniem obrazu i tekstu na równych prawach – zawiera
              ponad 150 rysunków wyjaśniających kwestie, które byłyby trudne do
              omówienia przy pomocy tekstu.
            </Typography>
          </Styled.Abstract>
          <Styled.ReadCTA>
            {!progress.link ? (
              <StartReadingLink />
            ) : (
              <ContinueLink style={{ position: "relative", marginRight: 0 }} />
            )}
          </Styled.ReadCTA>
          <Divider mt="7rem" mb="6.3rem" role="separator" />
          <FlexRow>
            <FlexColumn as="section" flex={1}>
              <Typography
                as="h2"
                variant="caption"
                mt="0 !important"
                mb="1.9rem !important"
              >
                TEKST
              </Typography>
              <Typography variant="body" typo="azo">
                Maja Starakiewicz
              </Typography>
            </FlexColumn>
            <FlexColumn
              as="section"
              flex={1}
              mt={{ xs: "6.3rem", sm: "6.3rem", md: "0" }}
            >
              <Typography
                as="h2"
                variant="caption"
                mt="0 !important"
                mb="1.9rem !important"
              >
                KONCEPCJA GRAFICZNA
              </Typography>
              <Typography variant="body" typo="azo">
                Jakub Woynarowski
              </Typography>
              <Typography variant="body" typo="azo">
                Maja Starakiewicz
              </Typography>
            </FlexColumn>
          </FlexRow>
          <FlexRow mt="6.3rem">
            <FlexColumn as="section" flex={1}>
              <Typography
                as="h2"
                variant="caption"
                mt="0 !important"
                mb="1.9rem !important"
              >
                WYDANIE II (CYFROWE):
              </Typography>
              <Typography variant="body" typo="azo" mr="2rem">
                Wydawnictwo PWSFTviT w Łodzi, <br />
                ul. Targowa 61/63, 90-323 Łódź
              </Typography>
              <Typography variant="body" typo="azo">
                2023
              </Typography>
              <Typography variant="body" typo="azo" mb="2rem">
                ISBN: 978-83-67397-16-2
              </Typography>
            </FlexColumn>
            <FlexColumn
              as="section"
              flex={1}
              mt={{ xs: "6.3rem", sm: "6.3rem", md: "0" }}
            >
              <Typography
                as="h2"
                variant="caption"
                mt="0 !important"
                mb="1.9rem !important"
              >
                WYDANIE I (PAPIEROWE):
              </Typography>
              <Typography variant="body" typo="azo">
                Korporacja Ha!art, Kraków 2019
              </Typography>
            </FlexColumn>
          </FlexRow>
          <Styled.AuthorsCTA>
            <mdx.Link to="/authors">
              <Typography variant="caption" fontWeight="normal" as="span">
                WIĘCEJ O AUTORACH
                <Styled.InlineImg
                  src={ArrowRight}
                  alt="dalej"
                  aria-label="Dalej"
                />
              </Typography>
            </mdx.Link>
          </Styled.AuthorsCTA>
        </Styled.Content>
        <Divider mt={{ xs: "7rem", lg: "11rem" }} mb="7rem" />
      </Styled.Layout>
    </ThemeProvider>
  )
}

export default AboutPage
