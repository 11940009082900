import styled from "styled-components"
import { FlexRow } from "../../components/system/flex-row"
import { between, up, only } from "styled-breakpoints"
import Eye from "../../images/eye.inline.svg"
import { FlexColumn } from "../../components/system/flex-column"

export const Layout = styled(FlexRow)`
  flex-wrap: wrap;
  padding: 0 1.8rem;
  background: ${({ theme }) => theme.colors.blue};

  ${between("md", "xl")} {
    padding: 0 3.5rem;
  }

  ${between("xl", "xxl")} {
    padding: 0 6.5rem;
  }

  ${up("xxl")} {
    padding: 0 14rem;
  }
`
export const EyeIcon = styled(Eye)`
  margin-left: 2rem;
  transform: translateY(0.3rem);

  ${only("md")} {
    ${between("sm", "lg", "portrait")} {
      margin-left: 3rem;
      transform: scale(1.4) translateY(-0.15rem);
    }
  }

  ${up("xl")} {
    transform: scale(1.2);
  }

  ${up("xxl")} {
    transform: scale(1.4) translateY(-0.15rem);
  }
`

export const Spacer = styled(FlexColumn)`
  display: none;
  flex: 1;
  padding-left: 5vw;

  ${up("md")} {
    flex: 4;
  }

  ${up("lg")} {
    display: initial;
    flex: 6;
  }
`

export const Content = styled(FlexColumn)`
  flex: 1;
  padding-right: 5vw;
  padding-left: 5vw;

  ${up("md")} {
    flex: 5;
  }

  ${up("lg")} {
    flex: 6;
    padding-left: 0;
  }
`

export const Abstract = styled(FlexRow)`
  margin-top: 8.9rem;
`

export const ReadCTA = styled(FlexRow)`
  justify-content: flex-end;
  text-align: end;
  margin-top: 4rem;
`

export const AuthorsCTA = styled(FlexRow)`
  justify-content: flex-end;
  text-align: end;
  margin-top: 4rem;

  ${up("md")} {
    margin-top: 2.9rem;
  }
`

export const InlineImg = styled.img`
  height: 1em;
  vertical-align: middle;
  margin-left: 2.2rem;
`
